import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import { getOrgGroups } from 'store/actions/organization.actions';
import { overwriteUserGroups } from 'store/actions/users.actions';
import {
  deleteOrganizationUser,
  updateOrganizationUserInfo,
} from 'store/reducers/organizationUsers';
import {
  optionalPhoneNumberRegex,
  phoneNumberCountryCode,
} from 'helpers/numbers';
import { handleError } from 'handleError';
import { useHistory } from 'react-router-dom';
import * as profileAPI from '../../api/profile.api';
import { RootState } from '../../store/rootReducer';
import Button from '../Shared/Button';
import './hiringTeam.scss';
import ModalForm, {
  ModalFormProps,
  ModalFormFieldTypes,
  ModalFormField,
} from '../Shared/ModalForm';
import { refreshCurrentTokens } from '../../store/actions/auth.actions';
import store from '../../store/store';
import { getUserLocationData } from '../../api/ip-info';
import OrganizationUsers from './OrganizationUsers';
import { HiringTeamUser } from './UsersTableFields';
import { hasOrgFeature, doesAccountForceSSO } from '../../Authorization/Check';

const HiringTeam: React.FC = () => {
  const dispatch = useDispatch();
  const [availableGroups, setAvailableGroups] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showSuspendUserModal, setShowSuspendUserModal] = useState(false);
  const [forceRefetch, setForceRefetch] = useState(0);
  const [selectedUser, setSelectedUser] = useState<HiringTeamUser>({
    id: 0,
    first_name: '',
    last_name: '',
    organisation_id: 0,
    Name: '',
    Email: '',
    'Last Login': '',
    'Added On': '',
    Permissions: '',
    'Phone Number': '',
    'MFA Enabled': false,
    groups: [],
    Status: '',
  });
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { groups } = useSelector((state: RootState) => state.organization);
  const { addToast } = useToasts();
  const [countryCode, setCountryCode] = useState('us');

  const history = useHistory();
  const canSSO = useMemo(() => hasOrgFeature('sso'), []);
  const canGroups = useMemo(() => hasOrgFeature('groups'), []);
  const hasAccessToMFA = useMemo(() => hasOrgFeature('mfa'), []);
  const canUserPermission = useMemo(
    () => hasOrgFeature('user_permissions'),
    []
  );
  const forceSSO = useMemo(() => doesAccountForceSSO(), []);

  useEffect(() => {
    getUserLocationData()
      .then((res) => {
        setCountryCode(res.data?.country?.toLowerCase());
      })
      .catch((err) => {
        handleError(err);
      });
  }, []);

  useEffect(() => {
    dispatch(getOrgGroups());
  }, [dispatch]);

  useEffect(() => {
    const groupIds = groups === null ? [] : Object.keys(groups);
    if (!canGroups) {
      setAvailableGroups([
        {
          label: 'Upgrade your subscription to access group management',
          clickUpgrade: true,
        },
      ]);
    } else if (groupIds.length > 0) {
      setAvailableGroups(
        groupIds
          .map((id) => {
            return {
              label: groups[id],
              value: parseInt(id, 10),
            };
          })
          // show list in alphabetical order
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [groups, canGroups]);

  const handleClickUpdateUser = (user: HiringTeamUser): void => {
    setSelectedUser(user);
    setShowUpdateUserModal(true);
  };

  if (!userDetails) {
    return <Skeleton />;
  }

  const handleAdd = async (formData): Promise<any> => {
    const selectedGroups = formData.groups.map((g) => g.value);
    if (
      formData.phone_number &&
      (formData.phone_number.length === 0 ||
        formData.phone_number.match(phoneNumberCountryCode))
    ) {
      formData.phone_number = null;
    }
    const jsonBody = {
      ...formData,
      organisation_id: userDetails.recruiter_detail.organisation_id,
    };
    jsonBody.groups = selectedGroups;
    return new Promise((resolve, _reject) => {
      profileAPI
        .addHiringTeamUser(jsonBody)
        .then((result) => {
          // footgun: result.data.id is not the user id
          dispatch(overwriteUserGroups(result.data.user_id, selectedGroups));
          setForceRefetch(forceRefetch + 1);
          resolve(result);
        })
        .catch((e) => {
          handleError(e);
          addToast({
            type: 'error',
            msg: e.response.data?.errors?.[0]?.detail ?? e.message,
          });
        });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = async (formData): Promise<any> => {
    const jsonBody = {
      user_id: selectedUser.id,
      organisation_id: selectedUser.organisation_id,
    };
    return profileAPI.deleteHiringTeamUser(jsonBody).then((result) => {
      dispatch(deleteOrganizationUser(selectedUser.id));
      return Promise.resolve(result);
    });
  };
  const handleSuspend = async (): Promise<any> => {
    return handleUpdate({
      status: selectedUser.Status === 'Suspended' ? 'Active' : 'Suspended',
    });
  };

  const getPermissionHelperText = (permissions: string): string => {
    let helperText: string;
    switch (permissions) {
      case 'Admin':
        helperText =
          "Access to their groups' assessments and candidates as well as full access to the user management and organization settings.";
        break;
      case 'Standard':
        helperText = "Access to their groups' assessments and candidates.";
        break;
      case 'Restricted':
        helperText = 'Can only access candidates they invited themselves.';
        break;
      case 'Owner':
        helperText =
          'Full access to all assessments, candidates, and settings.';
        break;
      default:
        helperText = '';
    }
    return helperText;
  };

  const handleUpdate = async (formData): Promise<any> => {
    const selectedGroups: number[] = (selectedUser.Permissions === 'Owner' ||
    !formData.groups
      ? selectedUser.groups
      : formData.groups
    ).map((g) => g.value);
    if (
      formData.phone_number &&
      (formData.phone_number.length === 0 ||
        formData.phone_number.match(phoneNumberCountryCode))
    ) {
      formData.phone_number = null;
    }
    const mfa_enabled =
      formData.phone_number === null ? false : selectedUser['MFA Enabled'];
    const jsonBody = {
      user_id: selectedUser.id,
      organisation_id: selectedUser.organisation_id,
      permissions: formData.permissions,
      phone_number: formData.phone_number,
      mfa_enabled,
      groups: selectedGroups,
      status: formData.status,
    };
    const userGroupIds = selectedUser.groups.map((g) => g.value);
    const currentUserGroupsUpdated =
      userDetails.id === selectedUser.id &&
      userGroupIds.sort().join(',') !== selectedGroups.sort().join(',');
    dispatch(overwriteUserGroups(selectedUser.id, selectedGroups));
    const data = await profileAPI
      .updateHiringTeamUser(jsonBody)
      .then((response) => {
        dispatch(
          updateOrganizationUserInfo({
            id: selectedUser.id,
            Permissions: formData.permissions,
            'Phone Number': formData.phone_number ?? null,
            'MFA Enabled': hasAccessToMFA ? mfa_enabled : false,
            Status: formData.status,
          })
        );
        return Promise.resolve(response);
      });
    if (currentUserGroupsUpdated) {
      store.dispatch(refreshCurrentTokens());
    }
    return data;
  };

  const addUserModalFormProps: ModalFormProps = {
    heading: 'Add User',
    fields: [
      {
        label: 'First Name',
        type: ModalFormFieldTypes.text,
        name: 'first_name',
      },
      {
        label: 'Last Name',
        type: ModalFormFieldTypes.text,
        name: 'last_name',
      },
      {
        label: 'Email',
        type: ModalFormFieldTypes.email,
        name: 'email',
      },
      {
        label: 'Job Title',
        type: ModalFormFieldTypes.text,
        name: 'job_title',
      },
      {
        label: 'Phone Number',
        type: ModalFormFieldTypes.tel,
        name: 'phone_number',
        countryCode,
      },
      {
        label: 'Permissions',
        type: ModalFormFieldTypes.radio,
        name: 'permissions',
        initialValue: canUserPermission ? 'Standard' : 'Admin',
        helperText: getPermissionHelperText('Standard'),
        radioOptions: [
          {
            label: 'Admin',
            value: 'Admin',
            helperText: getPermissionHelperText('Admin'),
          },
          {
            label: 'Standard',
            value: 'Standard',
            helperText: getPermissionHelperText('Standard'),
          },
          {
            label: 'Restricted',
            value: 'Restricted',
            helperText: getPermissionHelperText('Restricted'),
          },
        ],
      },
      {
        label: 'Groups',
        type: ModalFormFieldTypes.multiselect,
        name: 'groups',
        selectOptions: availableGroups,
        initialValue: [],
        selectProperties: {
          dropdownPlaceholder: 'Add to Group',
          noOptionsMessage: 'No groups available',
        },
      },
    ],
    submitButtonLabel: 'Add User',
    closeModal: () => {
      setShowAddUserModal(false);
    },
    handleSubmit: handleAdd,
    formSchema: Yup.object().shape({
      first_name: Yup.string()
        .max(50, 'Too Long')
        .required('First name is required'),

      last_name: Yup.string()
        .max(50, 'Too Long')
        .required('Last name is required'),

      phone_number: Yup.string()
        .matches(optionalPhoneNumberRegex, 'Invalid phone number')
        .nullable(),

      job_title: Yup.string()
        .max(250, 'Too Long')
        .required('Job title is required'),

      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required'),

      permissions: Yup.string().required('Permission level is required'),
    }),
    sendToast: (toast): void => {
      addToast(toast);
    },
    submitButtonLoadingText: 'Adding User...',
  };

  const getUpdateUserModalFormFields = (): Array<ModalFormField> => {
    const fields = [
      {
        ...addUserModalFormProps.fields[0],
        initialValue: selectedUser.first_name,
        disabled: true,
      },
      {
        ...addUserModalFormProps.fields[1],
        initialValue: selectedUser.last_name,
        disabled: true,
      },
      {
        ...addUserModalFormProps.fields[2],
        initialValue: selectedUser.Email,
        disabled: true,
      },
      {
        ...addUserModalFormProps.fields[3],
        initialValue: selectedUser['Job Title'],
        disabled: true,
      },
      {
        ...addUserModalFormProps.fields[4],
        initialValue: selectedUser['Phone Number'],
        disabled: false,
      },
      {
        ...addUserModalFormProps.fields[5],
        initialValue: selectedUser.Permissions,
        helperText: getPermissionHelperText(selectedUser.Permissions),
        disabled:
          selectedUser.Permissions === 'Owner' ||
          selectedUser.id === userDetails.id,
      },
      {
        ...addUserModalFormProps.fields[6],
        initialValue: selectedUser.groups,
      },
    ];
    if (selectedUser.Permissions === 'Owner') {
      // Do not allow owners to have their permissions changed
      fields[5].radioOptions = [
        {
          label: 'Owner',
          value: 'Owner',
          helperText: getPermissionHelperText('Owner'),
        },
      ];

      // remove the groups selection field if owner
      fields.pop();
    }
    return fields;
  };

  const updateUserModalFormProps: ModalFormProps = {
    heading: `Update ${selectedUser.first_name || ''} ${
      selectedUser.last_name || ''
    }`,
    fields: getUpdateUserModalFormFields(),
    submitButtonLabel: 'Save',
    handleSubmit: handleUpdate,
    closeModal: () => {
      setShowUpdateUserModal(false);
    },
    formSchema: addUserModalFormProps.formSchema,
    sendToast: addUserModalFormProps.sendToast,
    submitButtonLoadingText: 'Saving...',
  };

  const deleteUserModalFormProps: ModalFormProps = {
    heading: `Delete ${selectedUser.Name}`,
    subText: `Are you sure you want to remove ${selectedUser.Name} <${selectedUser.Email}>?\n\nThis action is irreversible.`,
    fields: [],
    submitButtonLabel: 'Remove User',
    handleSubmit: handleDelete,
    closeModal: () => {
      setShowDeleteUserModal(false);
    },
    formSchema: Yup.object().shape({}),
    sendToast: (toast): void => {
      addToast(toast);
    },
    submitButtonLoadingText: 'Removing...',
  };

  const suspendUserModalFormProps: ModalFormProps = {
    heading: `${
      selectedUser.Status === 'Suspended' ? 'Reactivate' : 'Suspend'
    } ${selectedUser.Name}`,
    subText: `Are you sure you want to ${
      selectedUser.Status === 'Suspended' ? 'reactivate' : 'suspend'
    } ${selectedUser.Name} <${selectedUser.Email}>?`,
    fields: [],
    submitButtonLabel: `${
      selectedUser.Status === 'Suspended' ? 'Reactivate' : 'Suspend'
    } User`,
    handleSubmit: handleSuspend,
    closeModal: () => {
      setShowSuspendUserModal(false);
    },
    formSchema: Yup.object().shape({}),
    sendToast: (toast): void => {
      addToast(toast);
    },
    submitButtonLoadingText:
      selectedUser.Status === 'Suspended' ? 'Reactivating...' : 'Suspending...',
  };
  return (
    <div id="hiringTeam">
      <div className="intro">
        <div className="desc">
          <h2 className="mb4">
            Manage Users
            {canSSO ? (
              <Button
                variant="primary md right"
                text="Configure Single Sign-On"
                onClick={() =>
                  history.push('/organization-settings#sso-settings')
                }
              />
            ) : null}
            <Button
              tooltip={
                canSSO && forceSSO
                  ? 'Users must be added via your SSO system as force SSO is enabled.'
                  : ''
              }
              disabled={canSSO && forceSSO}
              addButton
              onClick={() => {
                setShowAddUserModal(true);
              }}
              type="button"
              variant="primary md right"
              text="Add User"
            />
          </h2>
          <p>
            Add your colleagues to this account so they can assist with managing
            assessments.
          </p>
        </div>
      </div>
      <hr />
      <OrganizationUsers
        handleClickUpdateUser={handleClickUpdateUser}
        setSelectedUser={setSelectedUser}
        setShowDeleteUserModal={setShowDeleteUserModal}
        setShowSuspendUserModal={setShowSuspendUserModal}
        availableGroups={availableGroups}
        forceRefetch={forceRefetch}
      />
      {showAddUserModal && (
        <ModalForm
          heading={addUserModalFormProps.heading}
          fields={addUserModalFormProps.fields}
          submitButtonLabel={addUserModalFormProps.submitButtonLabel}
          closeModal={addUserModalFormProps.closeModal}
          handleSubmit={addUserModalFormProps.handleSubmit}
          formSchema={addUserModalFormProps.formSchema}
          sendToast={addUserModalFormProps.sendToast}
          submitButtonLoadingText={
            addUserModalFormProps.submitButtonLoadingText
          }
        />
      )}
      {showUpdateUserModal && (
        <ModalForm
          heading={updateUserModalFormProps.heading}
          fields={updateUserModalFormProps.fields}
          submitButtonLabel={updateUserModalFormProps.submitButtonLabel}
          closeModal={updateUserModalFormProps.closeModal}
          handleSubmit={updateUserModalFormProps.handleSubmit}
          formSchema={updateUserModalFormProps.formSchema}
          sendToast={updateUserModalFormProps.sendToast}
          submitButtonLoadingText={
            updateUserModalFormProps.submitButtonLoadingText
          }
        />
      )}
      {showDeleteUserModal && (
        <ModalForm
          heading={deleteUserModalFormProps.heading}
          fields={deleteUserModalFormProps.fields}
          submitButtonLabel={deleteUserModalFormProps.submitButtonLabel}
          closeModal={deleteUserModalFormProps.closeModal}
          handleSubmit={deleteUserModalFormProps.handleSubmit}
          formSchema={deleteUserModalFormProps.formSchema}
          sendToast={deleteUserModalFormProps.sendToast}
          submitButtonLoadingText={
            deleteUserModalFormProps.submitButtonLoadingText
          }
          subText={deleteUserModalFormProps.subText}
        />
      )}
      {showSuspendUserModal && <ModalForm {...suspendUserModalFormProps} />}
    </div>
  );
};

export default HiringTeam;
